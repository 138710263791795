var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"1200","scrollable":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',{staticClass:"caption"},[_c('v-row',[_c('v-col',{staticClass:"d-flex pa-0 ma-0 justify-end"},[_c('v-toolbar-items',[_c('v-btn',{attrs:{"icon":"","color":"black","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)],1)],1),_c('v-card-text',{staticClass:"mt-4 pt-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"item-key":"groupId","loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
                showCurrentPage: true,
                showFirstLastPage: true,
                'items-per-page-options': _vm.$_item_per_page
              }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.createdAt ? _vm.dayjs(item.createdAt).format('HH:mm') : '-')+" ")])]}},{key:"item.pickupDate",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.pickupDate ? _vm.dateFormat(item.pickupDate) : '-')+" ")])]}},{key:"item.checked",fn:function(ref){
              var item = ref.item;
return [_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}})]}}],null,true)})],1)],1)],1),(_vm.items.length)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"small":"","color":"primary","loading":_vm.isSubmiting,"disabled":!_vm.items.find(function (x) { return x.checked; })},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$_strings.common.SAVE)+" ")])],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }