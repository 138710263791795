var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pa-4"},[(_vm.$route.params.status === 'COMPLETED')?_c('FilterList',{attrs:{"label":"Pesanan Dibuat","filter":_vm.filter},on:{"fetchData":_vm.fetchData}}):_vm._e(),(_vm.$route.params.status === 'WAITING')?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.refreshData}},[_vm._v(" Refresh Pesanan ")])],1)],1):_vm._e(),_c('v-data-table',{staticClass:"mt-4",attrs:{"item-key":"groupId","calculate-widths":"","show-expand":"","expanded":_vm.expanded,"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"server-items-length":_vm.totalData,"options":_vm.pagination,"footer-props":{
      showCurrentPage: true,
      showFirstLastPage: true,
      'items-per-page-options': _vm.$_item_per_page
    }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:options":function($event){_vm.pagination=$event},"item-expanded":_vm.onExpanded},scopedSlots:_vm._u([{key:"item.editableFlag",fn:function(ref){
    var item = ref.item;
return [(_vm.$route.params.status === 'COMPLETED')?_c('span',[_vm._v(" "+_vm._s(item.editableFlag ? 'Ya' : 'Tidak')+" ")]):(_vm.menuFunctions.GABUNGKAN_PESANAN_ORACLE)?_c('section',[(item.updatingStatus)?_c('v-progress-circular',{attrs:{"size":25,"color":"primary","indeterminate":""}}):_c('v-checkbox',{staticClass:"ma-0 pa-0",attrs:{"hide-details":""},on:{"change":function($event){return _vm.handleCombineShipment(item, $event)}},model:{value:(item.editableFlag),callback:function ($$v) {_vm.$set(item, "editableFlag", $$v)},expression:"item.editableFlag"}})],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('ExpandedItemList',{attrs:{"parentMenuId":item.id,"isLoading":item.fetchingDetail,"items":item.child,"colspanLength":_vm.displayedHeader.length},on:{"fetchData":_vm.fetchData}})]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(_vm.$route.params.status === 'CHANGE_ROUTE' && _vm.menuFunctions.EDIT_RUTE_ORACLE_ORDER)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function () { return _vm.showDialogShipment(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(_vm.$route.params.status === 'COMPLETED')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"orange","small":"","icon":""},on:{"click":function () { return _vm.showDialogFormOracle(item, { resend: true }); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-cached ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.oracleData.RESEND_TO_ORACLE))])]):_vm._e(),(_vm.showBtnDialogShipmentDetail(item))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","icon":""},on:{"click":function () { return _vm.showDialogShipmentDetail(item); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e()]}}],null,true)}),_c('DialogShipment',{ref:"dialogShipment",on:{"fetchData":_vm.fetchData}}),_c('DialogShipmentDetail',{ref:"dialogShipmentDetail",on:{"fetchData":_vm.fetchData}}),_c('DialogFormObjectInputOracle',{ref:"dialogFormObjectInputOracle"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }