<template>
  <section class="pa-4">
    <FilterList
      v-if="$route.params.status === 'COMPLETED'"
      label="Pesanan Dibuat"
      :filter="filter"
      @fetchData="fetchData"
    />
    <v-row v-if="$route.params.status === 'WAITING'">
      <v-col>
        <v-btn
          color="primary"
          @click="refreshData"
        >
          Refresh Pesanan
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      class="mt-4"
      item-key="groupId"
      calculate-widths
      show-expand
      :expanded.sync="expanded"
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      :server-items-length="totalData"
      :options.sync="pagination"
      :footer-props="{
        showCurrentPage: true,
        showFirstLastPage: true,
        'items-per-page-options': $_item_per_page
      }"
      @item-expanded="onExpanded"
    >
      <template class="d-none" v-slot:[`item.editableFlag`]={item}>
        <span v-if="$route.params.status === 'COMPLETED'">
          {{ item.editableFlag ? 'Ya' : 'Tidak' }}
        </span>
        <section v-else-if="menuFunctions.GABUNGKAN_PESANAN_ORACLE">
          <v-progress-circular
            v-if="item.updatingStatus"
            :size="25"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <v-checkbox
            v-else
            class="ma-0 pa-0"
            hide-details
            v-model="item.editableFlag"
            @change="handleCombineShipment(item, $event)"
          />
        </section>
      </template>
      <template class="d-none" v-slot:expanded-item="{ headers, item }">
        <ExpandedItemList
          :parentMenuId="item.id"
          :isLoading="item.fetchingDetail"
          :items="item.child"
          :colspanLength="displayedHeader.length"
          @fetchData="fetchData"
        />
      </template>
      <template v-slot:[`item.actions`]={item}>
        <v-tooltip v-if="$route.params.status === 'CHANGE_ROUTE' && menuFunctions.EDIT_RUTE_ORACLE_ORDER" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              small
              icon
              @click="() => showDialogShipment(item)"
            >
              <v-icon>
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
        <v-tooltip v-if="$route.params.status === 'COMPLETED'" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="orange"
              small
              icon
              @click="() => showDialogFormOracle(item, { resend: true })"
            >
              <v-icon>
                mdi-cached
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.oracleData.RESEND_TO_ORACLE}}</span>
        </v-tooltip>
        <v-tooltip v-if="showBtnDialogShipmentDetail(item)" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="primary"
              small
              icon
              @click="() => showDialogShipmentDetail(item)"
            >
              <v-icon>
                mdi-magnify
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.EDIT}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <DialogShipment ref="dialogShipment" @fetchData="fetchData"/>
    <DialogShipmentDetail ref="dialogShipmentDetail" @fetchData="fetchData"/>
    <DialogFormObjectInputOracle ref="dialogFormObjectInputOracle" />
  </section>
</template>

<script>

import dayjs from 'dayjs';
import {
  handleSortBy,
  handlerPagination,
  defaultPagination,
  skipEmptyStringObject,
  dateFormat,
} from '@/helper/commonHelpers';
import FilterList from './FilterList.vue';
import ExpandedItemList from './ExpandedItemList.vue';
import DialogShipment from './Dialog/Shipment.vue';
import DialogShipmentDetail from './Dialog/ShipmentDetail.vue';
import DialogFormObjectInputOracle from './Dialog/FormObjectInputOracle';

export default {
  components: {
    ExpandedItemList,
    DialogShipment,
    DialogShipmentDetail,
    DialogFormObjectInputOracle,
    FilterList,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      expanded: [],
      search: this.$route.query.search || '',
      isLoading: false,
      headers: {
        WAITING: [
          {
            text: 'No Kendaraan',
            value: 'vehicleNo',
            width: '180px',
          },
          {
            text: 'Pesanan Dibuat',
            value: 'createdAt',
            width: '250px',
          },
          {
            text: 'Tanggal Pengiriman',
            value: 'pickupDate',
            sortable: false,
            width: '200px',
          },
          {
            text: 'Waktu Tunggu Pesanan',
            value: 'waitingTime',
            sortable: false,
            width: '200px',
          },
          {
            text: 'Ket Tarif',
            value: 'serviceType',
            width: '180px',
          },
          {
            text: 'Gabungkan Pesanan',
            value: 'editableFlag',
            width: '120px',
          },
          {
            text: '',
            value: 'data-table-expand',
            cellClass: 'clickable',
          },
        ],
        CHANGE_ROUTE: [
          {
            text: 'No Kendaraan',
            value: 'vehicleNo',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Pesanan Dibuat',
            value: 'createdAt',
            sortable: false,
            width: '250px',
          },
          {
            text: 'Tanggal Pengiriman',
            value: 'pickupDate',
            sortable: false,
            width: '200px',
          },
          {
            text: 'Ket Tarif',
            value: 'serviceType',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Asal',
            value: 'originLocationName',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Action',
            value: 'actions',
            sortable: false,
            width: '20px',
          },
          {
            text: '',
            value: 'data-table-expand',
            cellClass: 'clickable',
          },
        ],
        COMPLETED: [
          {
            text: 'No Kendaraan',
            value: 'vehicleNo',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Pesanan Dibuat',
            value: 'createdAt',
            sortable: false,
            width: '250px',
          },
          {
            text: 'Waktu Tunggu Pesanan',
            value: 'waitingTime',
            sortable: false,
            width: '200px',
          },
          {
            text: 'Ket Tarif',
            value: 'serviceType',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Gabungkan',
            value: 'editableFlag',
            sortable: false,
            width: '180px',
          },
          {
            text: 'Action',
            value: 'actions',
            sortable: false,
            width: '120px',
          },
          {
            text: '',
            value: 'data-table-expand',
            cellClass: 'clickable',
          },
        ],
      },
      items: [],
      totalData: 0,
      pagination: defaultPagination(),
      filter: {
        pickupDateStart: '',
        pickupDateEnd: '',
      },
    };
  },
  computed: {
    displayedHeader() {
      const { status } = this.$route.params;
      const { canUpdate } = this.userAccess;
      return this.headers[status].map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
      })).filter((header) => {
        if (!canUpdate) {
          return header.value !== 'actions';
        }
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    showBtnDialogShipmentDetail(item) {
      const isCompletedStatus = this.$route.params.status === 'COMPLETED';
      const currentStatus = item.statusDescription ? item.statusDescription : '';
      const statusCompleted = ['barang diterima utuh', 'selesai'].includes(currentStatus.toLowerCase());
      return isCompletedStatus && item.editableFlag && !statusCompleted;
    },
    async onExpanded({ item }) {
      if (item.child) return;
      try {
        this.$set(item, 'fetchingDetail', true);
        const result = await this.$_services.oracleData.getOrcleOrdersDetail({ groupId: item.groupId });
        this.$set(item, 'child', result.data);
      } finally {
        this.$delete(item, 'fetchingDetail');
      }
    },
    async handleCombineShipment(item, event) {
      try {
        this.$set(item, 'updatingStatus', true);
        await this.$_services.oracleData.combineShipment({ groupId: item.groupId, isCombine: event });
      } catch {
        this.$nextTick(() => {
          item.editableFlag = !event;
        });
      } finally {
        this.$delete(item, 'updatingStatus');
      }
    },
    handleSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
            page: 1,
          },
        });
        this.$emit('refetchData');
      }
    },
    async showDialogFormOracle(item, { resend }) {
      try {
        this.$root.$loading.show();
        const res = await this.$_services.oracleData.getLogOracleOrders({ groupId: item.groupId });
        this.$refs.dialogFormObjectInputOracle.dialog = true;
        this.$refs.dialogFormObjectInputOracle.showBtnResend = resend;
        this.$refs.dialogFormObjectInputOracle.groupId = item.groupId;
        this.$refs.dialogFormObjectInputOracle.form.jsonBody = JSON.stringify(res.data, null, 2);
      } finally {
        this.$root.$loading.hide();
      }
    },
    async showDialogShipment(item) {
      this.$refs.dialogShipment.groupId = item.groupId;
      this.$refs.dialogShipment.dialog = true;
    },
    async showDialogShipmentDetail(item) {
      this.$refs.dialogShipmentDetail.groupId = item.groupId;
      this.$refs.dialogShipmentDetail.vehicleNo = item.vehicleNo;
      this.$refs.dialogShipmentDetail.dialog = true;
    },
    async refreshData() {
      try {
        this.isLoading = true;
        await this.$_services.oracleData.refreshOrcleOrders();
        this.isLoading = false;
        this.fetchData();
      } catch {
        this.isLoading = false;
      }
    },
    async fetchData() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const { status } = this.$route.params;
        const {
          page, itemsPerPage: size, sortBy, sortDesc,
        } = this.pagination;
        const _filter = skipEmptyStringObject({
          ...this.filter,
          page: page - 1,
          size,
          sort: handleSortBy({ sortBy, sortDesc }),
        });
        const result = await this.$_services.oracleData.getListOrcleOrders({ filter: _filter, status });
        this.items = result.data.contents.map((r) => ({
          ...r,
          createdAt: r.createdAt ? `${dateFormat(r.createdAt)} ${dayjs(r.createdAt).format('HH:mm')}` : '-',
          pickupDate: r.pickupDate ? `${dateFormat(r.pickupDate)} ${dayjs(r.pickupDate).format('HH:mm')}` : '-',
          waitingTime: r.waitingTime ? dayjs(r.waitingTime).format('HH:mm') : '-',
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async fetchDetail(id) {
      try {
        this.$root.$loading.show();
        const result = await this.$_services.oracleData.getDetailInvoiceOracle(id);
        return result;
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
