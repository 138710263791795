<template>
  <section>
    <h4>{{label}}</h4>
    <v-row dense>
      <v-col cols="auto">
        <v-menu
          ref="menuFromDate"
          v-model="menuFromDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details
              outlined
              class="caption"
              :placeholder="$_strings.common.FROM_DATE"
              :value="dayjs(filter.pickupDateStart).format('DD-MM-YYYY')"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filter.pickupDateStart"
            no-title
            :max="filter.pickupDateEnd"
            @change="menuFromDate = false"
            locale="id"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-sm-flex d-none align-center" cols="auto">-</v-col>
      <v-col cols="auto">
        <v-menu
          ref="menuToDate"
          v-model="menuToDate"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              hide-details
              class="caption"
              outlined
              :value="dayjs(filter.pickupDateEnd).format('DD-MM-YYYY')"
              :placeholder="$_strings.common.TO_DATE"
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            :min="filter.pickupDateStart"
            v-model="filter.pickupDateEnd"
            no-title
            @change="menuToDate = false"
            locale="id"
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: 'filter-list',
  props: {
    label: String,
    filter: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    const { pickupDateStart, pickupDateEnd } = this.$route.query;
    const currentDate = dayjs().add('7', 'days').format('YYYY-MM-DD');
    this.filter.pickupDateStart = pickupDateStart || dayjs(currentDate).subtract(7, 'day').format('YYYY-MM-DD');
    this.filter.pickupDateEnd = pickupDateEnd || currentDate;
  },
  data() {
    return {
      menuFromDate: false,
      menuToDate: false,
    };
  },
  watch: {
    'filter.pickupDateStart': function pickupDateStart(newVal, oldVal) {
      if (this.$route.query.pickupDateStart !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            pickupDateStart: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchData');
      }
    },
    'filter.pickupDateEnd': function setpickupDateEnd(newVal, oldVal) {
      if (this.$route.query.pickupDateEnd !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            pickupDateEnd: newVal,
            page: 1,
          },
        });
      }
      if (oldVal) {
        this.$emit('fetchData');
      }
    },
  },
  methods: {
    dayjs,
  },
};
</script>

<style lang="scss" scoped>
.v-text-field--outlined.v-input--dense .v-input__control .v-text-field__slot > label.v-label {
  font-size: 10px !important;
  color: red !important;
}
</style>
