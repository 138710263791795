<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="1200"
    scrollable
  >
    <v-form ref="form">
      <v-card>
        <v-card-title class="caption">
          <v-row>
            <v-col class="d-flex pa-0 ma-0 justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="dialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-4 pt-2">
          <v-row>
            <v-col cols="12">
              <v-data-table
                item-key="groupId"
                :loading="isLoading"
                :headers="headers"
                :items="items"
                :server-items-length="totalData"
                :options.sync="pagination"
                :footer-props="{
                  showCurrentPage: true,
                  showFirstLastPage: true,
                  'items-per-page-options': $_item_per_page
                }"
              >
                <template v-slot:[`item.createdAt`]="{item}">
                  <span>
                    {{ item.createdAt ? dayjs(item.createdAt).format('HH:mm') : '-' }}
                  </span>
                </template>
                <template v-slot:[`item.pickupDate`]="{item}">
                  <span>
                    {{ item.pickupDate ? dateFormat(item.pickupDate) : '-' }}
                  </span>
                </template>
                <template v-slot:[`item.checked`]="{item}">
                  <v-checkbox
                    class="ma-0 pa-0"
                    hide-details
                    v-model="item.checked"
                  />
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="items.length">
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click="submit"
            :loading="isSubmiting"
            :disabled="!items.find((x) => x.checked)"
          >
            {{$_strings.common.SAVE}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { handleSortBy, skipEmptyStringObject, dateFormat } from '@/helper/commonHelpers';

const { CancelToken } = axios;
let source = CancelToken.source();

export default {
  data() {
    return {
      groupId: null,
      vehicleNo: null,
      isLoading: false,
      isSubmiting: false,
      dialog: false,
      items: [],
      headers: [
        {
          text: 'Shipper',
          value: 'companyName',
          sortable: false,
          width: '12%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'No Surat Jalan',
          value: 'travelDocumentNo',
          sortable: false,
          width: '12%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Nomor SJ Gabungan',
          value: 'travelDocumentNoGroup',
          sortable: false,
          width: '12%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Pesanan Dibuat (jam)',
          value: 'createdAt',
          sortable: false,
          width: '12%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Tanggal Pengiriman',
          value: 'pickupDate',
          sortable: false,
          width: '12%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Lokasi Asal',
          value: 'originLocationName',
          sortable: false,
          width: '14%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: 'Lokasi Tujuan Akhir',
          value: 'destinationLocationName',
          sortable: false,
          width: '14%',
          class: 'white--text primary text-capitalize',
        },
        {
          text: '',
          value: 'checked',
          sortable: false,
          width: '10%',
          class: 'white--text primary text-capitalize',
        },
      ],
      totalData: 0,
      pagination: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.pagination = {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
        };
        this.totalData = 0;
        this.items = [];
        source.cancel('CANCELED_BY_THE_USER');
        return;
      }
      source = CancelToken.source();
      this.fetchData();
    },
    pagination: {
      handler() {
        if (this.dialog) this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    dayjs,
    dateFormat,
    async fetchData() {
      if (this.isLoading) return;
      try {
        this.isLoading = true;
        const {
          page,
          itemsPerPage,
          sortBy,
          sortDesc,
        } = this.pagination;
        const filter = skipEmptyStringObject({
          page: page - 1,
          size: itemsPerPage,
          sort: handleSortBy({ sortBy, sortDesc }),
          vehicleNo: this.vehicleNo,
        });
        const result = await this.$_services.oracleData.getListDetailOrcleOrders({ status: 'WAITING', filter });
        this.items = result.data.contents.map((x) => ({
          ...x,
          checked: false,
        }));
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async submit() {
      const valid = this.$refs.form.validate();
      if (valid) {
        try {
          this.isSubmiting = true;
          const form = this.items.filter((x) => x.checked).map((x, i) => ({
            id: x.id,
            sequenceNo: i + 1,
            fastlogOriginId: x.fastlogOriginId,
            fastlogDestinationId: x.fastlogDestinationId,
          }));
          await this.$_services.oracleData.updateCompletedOrcaleShipment({ groupId: this.groupId, form, source });
          this.$dialog.notify.success('Berhasil');
          this.dialog = false;
          this.$emit('fetchData');
        } finally {
          this.isSubmiting = false;
        }
      }
    },
  },
};
</script>
